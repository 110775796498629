import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Nav } from 'react-bootstrap'
import cn from 'classnames'

import MENU from './constants'

const Menu = ({ variant }) => {
  return (
    <Nav className={cn({ [`nav--${variant}`]: variant })} as="ul">
      {MENU.map(({ name, link, isExternal }) => (
        <Nav.Item as="li" key={name}>
          {React.createElement(
            isExternal ? 'a' : Link,
            {
              className: 'nav-link',
              ...(isExternal
                ? { href: link, target: '_blank', rel: 'noopener noreferrer' }
                : { activeClassName: 'active', to: link }),
            },
            name
          )}
        </Nav.Item>
      ))}
    </Nav>
  )
}

Menu.defaultProps = {
  variant: '',
}

Menu.propTypes = {
  variant: PropTypes.string,
}

export default Menu
