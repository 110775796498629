import React from 'react'
import { Container } from 'react-bootstrap'
import PropTypes from 'prop-types'

import * as s from './Footer.module.scss'

const Footer = ({ siteTitle }) => {
  const currentYear = new Date().getFullYear()

  return (
    <Container as="footer" className={s.footer}>
      <span className={s.footer_copy}>
        © {currentYear} {siteTitle}. All Rights Reserved
      </span>
    </Container>
  )
}

Footer.defaultProps = {
  siteTitle: '',
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

export default Footer
