import React from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from '@gatsbyjs/reach-router'
import { Container } from 'react-bootstrap'
import PropTypes from 'prop-types'

import { Link, withPrefix } from 'gatsby'
import cn from 'classnames'
import Menu from '../Menu'

import * as s from './Header.module.scss'

const Header = ({ siteTitle }) => {
  const location = useLocation()
  const isHomepage = location.pathname === withPrefix('/')

  const Logo = isHomepage ? 'div' : Link

  return (
    <Container as="header" className={s.header}>
      <Logo className={cn(s.header__logo, { [s.link]: !isHomepage })} to="/">
        {siteTitle}
      </Logo>

      <Menu variant="header" />
    </Container>
  )
}

Header.defaultProps = {
  siteTitle: '',
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

export default Header
