const MENU = [
  {
    name: 'AQI',
    link: '/aqi',
  },
  {
    name: 'Радіаційний фон',
    link: 'https://www.saveecobot.com/radiation/lvivska-oblast',
    isExternal: true,
  },
  {
    name: 'Сніголавинні попередження',
    link: 'https://www.meteo.gov.ua/ua/Sniholavinni-poperedzhennya',
    isExternal: true,
  },
]

export default MENU
